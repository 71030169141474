@import '../../styles/customMediaQueries.css';

.responsiveContainer {
  composes: globalResponsiveContainer from global;
}

.listingPanel {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  padding: 0;
  color: var(--colorOrange);
  font-family: var(--fontFamilyRoboto);
}

.listingCards {
  gap: 40px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1200px) {
    row-gap: 40px;
    column-gap: 34px;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

.noRFQ {
  margin: 0;
  padding: 0;
  opacity: 0.4;
  color: var(--colorTextLightGray);
  font-family: var(--fontFamilyRoboto);
}
